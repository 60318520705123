body {
  --foreground: black;
  --background: white;
  --offset: #eeeeee;
  --snow: rgb(228, 248, 255);

  margin: 0px;
  background-color: var(--background);
  color: var(--foreground);

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
* {
  /* disable ios blinking on touch */
  -webkit-tap-highlight-color: transparent;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotate {
  animation-name: rotation;
  animation-duration: 2s;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
